import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import HesketLanding from '../components/HesketLanding'

import pic08 from '../assets/images/hesket/st-charles-811.jpg'
import pic09 from '../assets/images/hesket/st-charles-811.jpg'
import pic10 from '../assets/images/hesket/st-charles-811.jpg'
import pic11 from '../assets/images/hesket/st-charles-811.jpg'

const Hesket = (props) => (
    <Layout>
        <Helmet>
            <title>Hesket House - Victoria Heritage Apartments</title>
            <meta name="description" content="Hesket House. Victoria British Columbia. Victoria Heritage Apartments." />
        </Helmet>

        <HesketLanding />

        <div id="main">
            <section id="one" className="spotlights">
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic11} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>About</h3>
                            </header>
                            <p>This large 2-story house mixes many Tudor Revival characteristics: a combination of claddings including half-timbering, shingled siding and masonry, and a porte-cochere. It has a front gabled roof and gabled extensions attached to either side. The first storey is made of brick with square brick piers supporting a porte-cochere which braces a flat top balcony. The second floor is predominantly half-timbered, which carries into the gable with a course of quatrefoils below a cantilevaerd and bracketed box bay. Above all of this, the gable is shingled. The platform on top of the porte-cochere repeats the same quartefoil design and it is repeated again on a balcony at the back. Most of these fine points as well as the porte-cochere were added by Samuel Maclure in 1925-26. The back gable is of a much more reduced design, with finials and pendants. Most windows are multi-over-single-pane. There is clean leaded glass in a larger stair window, which is three stashes wide. Only one of the brick chimneys retains its original corbelleed cap.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic09} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Size & Scale</h3>
                            </header>
                            <p>With a total suite count of 12 units, Hesket House is 3 stories high and is roughly 10,000 square feet in size.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic08} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Location</h3>
                            </header>
                            <p>Located at 1320 Rockland avenue, Heskethouse is only a few short blocks away from beautiful downtown Victoria. Perched on a craggy hilltop, Rockland is an elegant neighbourhood known for Craigdarroch Castle, an ornate Victorian home built by coal baron Robert Dunsmuir. Grand Arts and Crafts mansions line the area’s leafy streets, while Government House, the lieutenant governor’s residence, has manicured gardens and woodland trails. Exhibits at the Art Gallery of Greater Victoria include paintings by BC artist Emily Carr.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic10} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                          <header className="major">
                              <h3>Design</h3>
                          </header>
                          <p>It has a steeply-hipped roof with exposed rafter tails, many shed-roofed dormers, and one gabled dormer on the main Gillespie Pl façade. There is a large 2½-storey, half-timbered, gabled bay on the Rockland Av façade, with bracketed bargeboards and a drop finial. It has an angled bay on the main floor below an angled balcony; to the right is a large square balcony over a glassed-in main floor extension.</p>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Hesket
