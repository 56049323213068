import React from 'react'

const HesketLanding = (props) => (
    <section id="galtbanner" className="style2">
        <div className="inner">
            <header className="major">
                <h1>Hesket <span>est. 1912</span></h1>
            </header>
            <p>
            Situated at 811 St. Charles Avenue, Hesket is a beautiful heritage mansion converted into suites. Located in the Rockland neighbourhood, Hesket is only a short distance from downtown Victoria.
            </p>
        </div>
    </section>
)

export default HesketLanding